import React, { useState, useEffect } from 'react';
import { Button, Checkbox, MultiSelect, Loading } from '@carbon/react'; // Import Loading component
import { useNavigate } from 'react-router-dom';

const GitHubIntegrationPage = ({ token, user }) => {
  const navigate = useNavigate();
  const [isConnected, setIsConnected] = useState(false);
  const [repositories, setRepositories] = useState([]);
  const [selectedRepos, setSelectedRepos] = useState([]);
  const [allowPush, setAllowPush] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    setIsLoading(true); // Set loading to true when the API call starts
    fetch('/mlapi/premium/integration/github/status', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsConnected(data.isConnected);
        if (data.isConnected) {
          const allRepos = data.repositories || [];
          const savedSelected = data.selectedRepos || [];

          setRepositories(allRepos);
          setSelectedRepos(
            savedSelected
              .map((sel) => allRepos.find((repo) => repo.id === sel.id) || sel)
              .filter(Boolean)
          );

          setAllowPush(data.allowPush || false);
        }
      })
      .catch(console.error)
      .finally(() => setIsLoading(false)); // Set loading to false when the API call completes
  }, [token]);

  const handleConnect = () => {
    fetch('/mlapi/premium/integration/github/connect', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.authUrl) window.location.href = data.authUrl;
        else throw new Error('No auth URL');
      })
      .catch((error) => {
        console.error('GitHub connection error:', error);
        alert('Connection initiation failed');
      });
  };

  const handleDisconnect = () => {
    if (
      window.confirm(
        'Disconnecting GitHub will revoke access and remove all synced data.'
      )
    ) {
      fetch('/mlapi/premium/integration/github/disconnect', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(() => {
          setIsConnected(false);
          setRepositories([]);
          setSelectedRepos([]);
          setAllowPush(false);
          alert('GitHub disconnected');
        })
        .catch(console.error);
    }
  };

  const handleSaveSettings = () => {
    const minimalRepos = selectedRepos.map(({ id, name, full_name }) => ({
      id,
      name,
      full_name,
    }));

    fetch('/mlapi/premium/integration/github/save-settings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        selectedRepos: minimalRepos,
        allowPush,
      }),
    })
      .then((response) => {
        if (!response.ok) throw new Error('Save failed');
        navigate('/');
      })
      .catch((error) => {
        console.error('Save error:', error);
        alert('Failed to save settings');
      });
  };

  return (
    <div className="github-integration-page">
      <div className="github-integration-container">
        <h1>GitHub Integration</h1>

        {/* Show loading spinner while the API call is in progress */}
        {isLoading ? (
          <Loading withOverlay={false} active={isLoading} />
        ) : (
          <>
            {!isConnected ? (
              <>
                <div className="integration-description">
                  <p>
                    Fresh Focus AI can help you interact with GitHub in many ways, including reviewing files, managing branches, creating pull requests, handling issues, and more.
                  </p>
                  <p>
                    <strong>Available Operations Include:</strong>
                  </p>
                  <ul>
                    <li>
                      <strong>Create Pull Request:</strong> "Create a pull request for src/App.js to add useful debug logging"
                    </li>
                    <li>
                      <strong>Review File:</strong> "Show me the contents of <code>src/App.js</code>"
                    </li>
                    <li>
                      <strong>List Tree</strong> "Show the tree of files in my github project myProject"
                    </li>
                    <li>
                      <strong>List Files (shows just single directory):</strong> "List the files in my repository or in <code>src/</code>"
                    </li>
                    <li>
                      <strong>Create File:</strong> "Create a new file called <code>src/newComponent.jsx</code> with my component code"
                    </li>
                    <li>
                      <strong>Update File:</strong> "Update the file <code>src/oldComponent.jsx</code> with revised code"
                    </li>
                    <li>
                      <strong>Create Branch:</strong> "Create a branch called <code>feature/new-ui</code>"
                    </li>
                    <li>
                      <strong>List Branches:</strong> "List all branches in my repository"
                    </li>
                    <li>
                      <strong>List Commits:</strong> "Show me the latest commits in my repository"
                    </li>
                    <li>
                      <strong>List Pull Requests:</strong> "List all open pull requests in my repository"
                    </li>
                    <li>
                      <strong>Merge Pull Request:</strong> "Merge pull request <code>#23</code>"
                    </li>
                    <li>
                      <strong>Create Issue:</strong> "Create an issue titled 'Bug in login' with details"
                    </li>
                    <li>
                      <strong>List Issues:</strong> "List all open issues in my repository"
                    </li>
                    <li>
                      <strong>Comment on Issue:</strong> "Add comment 'Investigating the bug' to issue <code>#23</code>"
                    </li>
                    <li>
                      <strong>Delete File:</strong> "Delete the file <code>docs/old-guide.md</code>"
                    </li>
                  </ul>
                </div>
                <p>
                  To start using these powerful features, please connect your GitHub account.
                </p>
                <Button kind="primary" onClick={handleConnect}>
                  Connect with GitHub
                </Button>
              </>
            ) : (
              <>
                <p>
                  GitHub account connected. Select repositories to grant access:
                </p>
                <MultiSelect
                  className="repo-select"
                  id="repos-select"
                  label="Available Repositories"
                  items={repositories}
                  itemToString={(item) => item?.full_name || ''}
                  selectedItems={selectedRepos}
                  onChange={({ selectedItems }) => setSelectedRepos(selectedItems)}
                />
                <p>&nbsp;</p>
                <Checkbox
                  id="write-access"
                  labelText="Allow write access (create branches, open PRs, modify code)"
                  checked={allowPush}
                  onChange={(_, { checked }) => setAllowPush(checked)}
                />
                <div className="github-examples" style={{ marginTop: '2rem' }}>
                  <h2>GitHub Operation Examples</h2>
                  <ul>
                    <li>
                      <strong>Create Pull Request:</strong> "Create a pull request for src/App.js to add useful debug logging"
                    </li>
                    <li>
                      <strong>Review File:</strong> "Show me the contents of <code>src/App.js</code>"
                    </li>
                    <li>
                      <strong>List Tree</strong> "Show the tree of files in my github project myProject"
                    </li>
                    <li>
                      <strong>List Files:</strong> "List the files in my repository or in <code>src/</code>"
                    </li>
                    <li>
                      <strong>Create File:</strong> "Create a new file called <code>src/newComponent.jsx</code> with my component code"
                    </li>
                    <li>
                      <strong>Update File:</strong> "Update the file <code>src/oldComponent.jsx</code> with revised code"
                    </li>
                    <li>
                      <strong>Create Branch:</strong> "Create a branch called <code>feature/new-ui</code>"
                    </li>
                    <li>
                      <strong>List Branches:</strong> "List all branches in my repository"
                    </li>
                    <li>
                      <strong>List Commits:</strong> "Show me the latest commits in my repository"
                    </li>
                    <li>
                      <strong>List Pull Requests:</strong> "List all open pull requests in my repository"
                    </li>
                    <li>
                      <strong>Merge Pull Request:</strong> "Merge pull request <code>#23</code>"
                    </li>
                    <li>
                      <strong>Create Issue:</strong> "Create an issue titled 'Bug in login' with details"
                    </li>
                    <li>
                      <strong>List Issues:</strong> "List all open issues in my repository"
                    </li>
                    <li>
                      <strong>Comment on Issue:</strong> "Add comment 'Investigating the bug' to issue <code>#23</code>"
                    </li>
                    <li>
                      <strong>Delete File:</strong> "Delete the file <code>docs/old-guide.md</code>"
                    </li>
                  </ul>
                </div>
                <div className="button-group" style={{ marginTop: '2rem' }}>
                  <Button kind="danger" onClick={handleDisconnect}>
                    Disconnect GitHub
                  </Button>
                  <Button kind="secondary" onClick={() => navigate('/')}>
                    Cancel
                  </Button>
                  <Button kind="primary" onClick={handleSaveSettings}>
                    Save Settings
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GitHubIntegrationPage;