import React, { useState, useEffect } from 'react';
import { Button, Checkbox, MultiSelect, Loading } from '@carbon/react'; // Import Loading component
import { useNavigate } from 'react-router-dom';

const EvernoteIntegrationPage = ({ token, user }) => {
  const navigate = useNavigate();
  const [isConnected, setIsConnected] = useState(false);
  const [notebooks, setNotebooks] = useState([]);
  const [selectedNotebooks, setSelectedNotebooks] = useState([]);
  const [allowCreateNotes, setAllowCreateNotes] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    setIsLoading(true); // Set loading to true when the API call starts
    // Check if the user is already connected to Evernote
    fetch('/api/premium/evernote/status', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsConnected(data.isConnected);
        if (data.isConnected) {
          // All available notebooks
          const allNotebooks = data.notebooks || [];

          // The user's already-selected notebooks (GUIDs)
          const savedSelected = data.selectedNotebooks || [];

          // Store the full notebooks array
          setNotebooks(allNotebooks);

          // Match saved GUIDs to the actual notebook objects from allNotebooks
          const matchedSelections = savedSelected
            .map((sel) => allNotebooks.find((nb) => nb.guid === sel.guid))
            .filter(Boolean);

          setSelectedNotebooks(matchedSelections);

          // Whether user allows creating notes
          if (typeof data.allowCreateNotes === 'boolean') {
            setAllowCreateNotes(data.allowCreateNotes);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching Evernote status:', error);
      })
      .finally(() => setIsLoading(false)); // Set loading to false when the API call completes
  }, [token]);

  const handleConnect = () => {
    fetch('/api/premium/evernote/connect', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to initiate Evernote connection.');
        }
        return response.json();
      })
      .then((data) => {
        // Use 'authUrl' since that's what the backend returns
        if (data.authUrl) {
          window.location.href = data.authUrl;
        } else {
          throw new Error('No authUrl returned from server.');
        }
      })
      .catch((error) => {
        console.error('Error connecting to Evernote:', error);
        alert('An error occurred while initiating Evernote connection.');
      });
  };

  const handleDisconnect = () => {
    if (
      window.confirm(
        'Are you sure you want to disconnect Evernote? This will revoke access and remove all synced data.'
      )
    ) {
      fetch('/api/premium/evernote/disconnect', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to disconnect Evernote.');
          }
          alert('Evernote has been disconnected.');
          setIsConnected(false);
          setNotebooks([]);
          setSelectedNotebooks([]);
          setAllowCreateNotes(false);
        })
        .catch((error) => {
          console.error('Error disconnecting Evernote:', error);
          alert('An error occurred while disconnecting Evernote.');
        });
    }
  };

  const handleSaveSettings = () => {
    // Map only the fields you want to store
    const minimalSelectedNotebooks = selectedNotebooks.map((nb) => ({
      guid: nb.guid,
      name: nb.name,
    }));

    fetch('/api/premium/evernote/save-settings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        selectedNotebooks: minimalSelectedNotebooks,
        allowCreateNotes,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to save Evernote settings.');
        }
        //alert('Evernote settings saved successfully.');
        navigate('/');
      })
      .catch((error) => {
        console.error('Error saving Evernote settings:', error);
        alert('An error occurred while saving your Evernote settings.');
      });
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <div className="evernote-integration-page">
      <div className="evernote-integration-container">
        <h1>Evernote Integration</h1>

        {/* Show loading spinner while the API call is in progress */}
        {isLoading ? (
          <Loading withOverlay={false} active={isLoading} />
        ) : (
          <>
            {!isConnected ? (
              <>
                <p>
                  Connect your Evernote account to allow Fresh Focus AI to read and
                  create notes on your behalf. This enables the AI assistant to
                  access your Evernote notes and enhance your experience. You may
                  restrict Fresh Focus AI access to certain Evernote notebooks or read access only.
                </p>
                <p>
                  This will allow you to make requests like: "Please
                  search stock market news for today and save it to my Evernote notebook
                  Stocks with an appropriate title." or "Please read my Evernote
                  note Problem1 in my AI notebook, solve it, and save it
                  as Solution1 in that notebook.
                </p>
                <Button kind="primary" onClick={handleConnect}>
                  Connect with Evernote
                </Button>
              </>
            ) : (
              <>
                <p>
                  Your Evernote account is connected. Select the notebooks you want
                  to grant access to.
                </p>
                <p>
                  Now you may issue requests like: "Please
                  search stock market news for today and save it to my Evernote notebook
                  Stocks with an appropriate title." or "Please read my Evernote
                  note Problem1 in my AI notebook, solve it, and save it
                  as Solution1 in that notebook."
                </p>
                <h2>Select Notebooks</h2>
                <MultiSelect
                  className="multi-select" // make sure you add this
                  id="notebooks-select"
                  label="Available Notebooks"
                  items={notebooks}
                  itemToString={(item) => (item ? item.name : '')}
                  selectedItems={selectedNotebooks}
                  onChange={({ selectedItems }) => setSelectedNotebooks(selectedItems)}
                />
                <Checkbox
                  id="create-notes"
                  labelText="Allow Fresh Focus AI to create, update, or delete notes in Evernote"
                  checked={allowCreateNotes}
                  onChange={(evt, { checked }) => setAllowCreateNotes(checked)}
                />
                <div className="button-group" style={{ marginTop: '2rem' }}>
                  <Button kind="danger" onClick={handleDisconnect}>
                    Disconnect Evernote
                  </Button>
                  <Button kind="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button kind="primary" onClick={handleSaveSettings}>
                    Save Settings
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EvernoteIntegrationPage;