// FFHeader.js
import React, { useState, useEffect } from 'react';
import {
  Login,
  Logout,
  UserAvatar,
  Purchase,
  Settings,
  UserAccess,
} from '@carbon/react/icons';
import { useNavigate, Link } from 'react-router-dom';
import {
  Header,
  HeaderName,
  HeaderMenuButton,
  HeaderGlobalBar,
  HeaderGlobalAction,
  OverflowMenu,
  OverflowMenuItem,
  SkipToContent,
  SideNav,
  SideNavItems,
  SideNavLink,
  Theme,
} from '@carbon/react';

const FFHeader = ({
  isLoggedIn,
  user,
  email,
  onLogout,
  isSideNavExpanded,
  onClickSideNavExpand,
}) => {
  const username =
    isLoggedIn && email ? email.split('@')[0].slice(0, 14) : null;
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 670);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 670);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Theme theme="g100">
      <Header aria-label="Application Header">
        <SkipToContent />
        {/* Always display the HeaderMenuButton */}
        <HeaderMenuButton
          className="always-visible-menu-button" // Add this line
          aria-label="Open menu"
          onClick={onClickSideNavExpand}
          isActive={isSideNavExpanded}
        />
        <HeaderName as={Link} to="/" prefix="">
          {isSmallScreen ? 'FFAI' : 'Fresh Focus AI'}
        </HeaderName>
        <HeaderGlobalBar>
          {isLoggedIn ? (
            <>
              {/* Display Username */}
              <div className="username-text">{username}</div>

              {/* User Avatar with OverflowMenu */}
              <HeaderGlobalAction
                aria-label="Profile"
                tooltipAlignment="end"
                tooltipPosition="left"
                className="user-avatar-action"
              >
                <OverflowMenu
                  aria-label="User Profile Menu"
                  renderIcon={() => <UserAvatar size={32} />}
                  direction="bottom"
                  flipped
                  className="username-overflow-menu"
                >
                  <OverflowMenuItem
                    itemText={
                      <>
                        <Purchase size={20} /> &nbsp;&nbsp;Billing
                      </>
                    }
                    onClick={() => {
                      navigate('/billing');
                    }}
                  />
                  <OverflowMenuItem
                    itemText={
                      <>
                        <Settings size={20} /> &nbsp;&nbsp;Settings
                      </>
                    }
                    onClick={() => {
                      navigate('/settings');
                    }}
                  />
                  <OverflowMenuItem
                    itemText={
                      <>
                        <UserAccess size={20} /> &nbsp;&nbsp;Privacy
                      </>
                    }
                    onClick={() => {
                      navigate('/privacy');
                    }}
                  />
                  <OverflowMenuItem
                    itemText={
                      <>
                        <Logout size={20} /> &nbsp;&nbsp;Logout
                      </>
                    }
                    onClick={onLogout}
                  />
                </OverflowMenu>
              </HeaderGlobalAction>
            </>
          ) : (
            <HeaderGlobalAction
              id="login-link"
              aria-label="Log in"
              onClick={handleLoginClick}
              tooltipAlignment="end"
            >
              <Login size={20} /> &nbsp; Log in
            </HeaderGlobalAction>
          )}
        </HeaderGlobalBar>
      </Header>

      {/* Side Navigation */}
      <SideNav
        aria-label="Side navigation"
        expanded={isSideNavExpanded}
        isPersistent={false}
        isFixedNav={false} // Not fixed; always collapsible
        isRail={false} // Not in rail mode
        onOverlayClick={onClickSideNavExpand}
      >
        <SideNavItems>
          <SideNavLink
            as={Link}
            to="/integration/email"
            onClick={onClickSideNavExpand}
          >
            Email Integration
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/integration/evernote"
            onClick={onClickSideNavExpand}
          >
            Evernote Integration
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/integration/github"
            onClick={onClickSideNavExpand}
          >
            GitHub Integration
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/integration/google-drive"
            onClick={onClickSideNavExpand}
          >
            Google Drive Integration
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/integration/s3"
            onClick={onClickSideNavExpand}
          >
            S3 Storage Integration
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/integration/sms"
            onClick={onClickSideNavExpand}
          >
            Text Message (SMS) Integration
          </SideNavLink>
          <SideNavLink
            as={Link}
            to="/integration/custom-api"
            onClick={onClickSideNavExpand}
          >
            Custom API Integrations
          </SideNavLink>
        </SideNavItems>
      </SideNav>
    </Theme>
  );
};

export default FFHeader;
