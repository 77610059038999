import React, { useState, useEffect } from 'react';
import { Button, TextInput, Loading, Toggle } from '@carbon/react';
import { useNavigate } from 'react-router-dom';

const S3IntegrationPage = ({ token, user }) => {
  const navigate = useNavigate();
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [credentials, setCredentials] = useState({
    accessKeyId: '',
    secretAccessKey: '',
    bucketName: '',
    region: 'us-east-1',
    pathPrefix: '',
  });
  // Renamed the state variable to better reflect its purpose.
  const [restrictAccess, setRestrictAccess] = useState(false);
  const [allowWrite, setAllowWrite] = useState(false);

  useEffect(() => {
    fetch('/mlapi/premium/integration/s3/status', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isConnected) {
          setIsConnected(true);
          setCredentials({
            accessKeyId: data.maskedAccessKey || '',
            secretAccessKey: '', // Never show actual secret key
            bucketName: data.bucketName || '',
            region: data.region || 'us-east-1',
            pathPrefix: data.pathPrefix || '',
          });
          setAllowWrite(data.allowWrite || false);
        }
        setIsLoading(false);
      })
      .catch(console.error);
  }, [token]);

  const handleInputChange = (field) => (e) => {
    setCredentials((prev) => ({ ...prev, [field]: e.target.value }));
  };

  const handleConnect = (e) => {
    e.preventDefault();
    setIsLoading(true);

    fetch('/mlapi/premium/integration/s3/connect', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...credentials,
        allowWrite,
      }),
    })
      .then((response) => {
        if (!response.ok) throw new Error('Connection failed');
        return response.json();
      })
      .then(() => {
        setIsConnected(true);
        navigate('/');
      })
      .catch((error) => {
        console.error('S3 connection error:', error);
        alert('Failed to connect to S3');
      })
      .finally(() => setIsLoading(false));
  };

  const handleDisconnect = () => {
    if (window.confirm('Disconnecting S3 will remove all stored credentials and access.')) {
      fetch('/mlapi/premium/integration/s3/disconnect', {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(() => {
          setIsConnected(false);
          setCredentials({
            accessKeyId: '',
            secretAccessKey: '',
            bucketName: '',
            region: 'us-east-1',
            pathPrefix: '',
          });
          alert('S3 disconnected');
        })
        .catch(console.error);
    }
  };

  return (
    <div className="s3-integration-page">
      <div className="s3-integration-container">
        <h1>Amazon S3 Integration</h1>

        {isLoading ? (
          <Loading withOverlay={false} active={isLoading} />
        ) : (
          <form onSubmit={handleConnect}>
            {!isConnected ? (
              <>
                <div className="integration-description">
                  <p>
                    Connect your S3 storage to enable file management capabilities. 
                    Fresh Focus AI can help you:
                  </p>
                  <ul>
                    <li>Retrieve and analyze files from your S3 buckets</li>
                    <li>Store processed files and analysis results</li>
                    <li>Manage file versions and metadata</li>
                    <li>Generate pre-signed URLs for secure access</li>
                    <li>Monitor storage metrics and usage patterns</li>
                  </ul>
                </div>

                <TextInput
                  id="accessKey"
                  labelText="AWS Access Key ID"
                  value={credentials.accessKeyId}
                  onChange={handleInputChange('accessKeyId')}
                  required
                  autoComplete="off"
                />

                <TextInput
                  id="secretKey"
                  labelText="AWS Secret Access Key"
                  value={credentials.secretAccessKey}
                  onChange={handleInputChange('secretAccessKey')}
                  type="password"
                  required
                  autoComplete="off"
                />

                <TextInput
                  id="bucketName"
                  labelText="Bucket Name"
                  value={credentials.bucketName}
                  onChange={handleInputChange('bucketName')}
                  required
                />

                <TextInput
                  id="region"
                  labelText="AWS Region"
                  value={credentials.region}
                  onChange={handleInputChange('region')}
                  required
                />

                {/* Toggle for restricting AI access */}
                <p>&nbsp;</p>
                <div style={{ marginBottom: '1rem' }}>
                  <Toggle
                    labelText="Restrict AI Access"
                    id="restrict-ai-access-toggle"
                    toggled={restrictAccess}
                    onToggle={() => setRestrictAccess(!restrictAccess)}
                  />
                </div>

                {restrictAccess && (
                  <TextInput
                    id="pathPrefix"
                    labelText="Path Prefix (optional)"
                    value={credentials.pathPrefix}
                    onChange={handleInputChange('pathPrefix')}
                    helperText="Specify a file or directory prefix to restrict AI access"
                  />
                )}

                {/* Toggle for allowing write operations */}
                <div style={{ marginBottom: '1rem' }}>
                  <Toggle
                    labelText="Allow Write Operations"
                    id="write-toggle"
                    toggled={allowWrite}
                    onToggle={(e) => setAllowWrite(e)}
                    helperText="Enable file uploads and modifications"
                  />
                </div>

                <div className="button-group" style={{ marginTop: '2rem' }}>
                  <Button kind="secondary" onClick={() => navigate('/')}>
                    Cancel
                  </Button>
                  <Button type="submit" kind="primary">
                    Connect S3
                  </Button>
                </div>
              </>
            ) : (
              <>
                <p>
                  S3 bucket {credentials.bucketName} connected in {credentials.region} region.
                </p>
                <div className="s3-examples" style={{ marginTop: '2rem' }}>
                  <h2>Example Operations</h2>
                  <ul>
                    <li>
                      <strong>Analysis:</strong> "Analyze Q1.csv on my S3 storage. Look for patterns
                      and save the analysis on S3 storage Q1_Analysis.md"
                    </li>
                    <li>
                      <strong>List Files:</strong> "Show all PDF files in the documents/"
                    </li>
                    <li>
                      <strong>Retrieve File:</strong> "Get the contents of reports/q4-summary.md"
                    </li>
                    <li>
                      <strong>Upload File:</strong> "Save this analysis as processed/data.json"
                    </li>
                    <li>
                      <strong>Create Folder:</strong> "Make a new directory for user-uploads"
                    </li>
                    <li>
                      <strong>Generate URL:</strong> "Create a 24-hour access link for image.jpg"
                    </li>
                    <li>
                      <strong>Check File Details:</strong> "What's the size and type of dataset.csv?"
                    </li>
                    <li>
                      <strong>Delete File:</strong> "Remove the temporary file draft.txt"
                    </li>
                    <li>
                      <strong>Update File:</strong> "Replace config.yaml with updated settings"
                    </li>
                  </ul>

                  <h3 style={{ marginTop: '1rem' }}>Advanced Operations</h3>
                  <ul>
                    <li>
                      <strong>Compare Versions:</strong> "Show differences between draft-v1 and
                      draft-v2"
                    </li>
                    <li>
                      <strong>Manage Access:</strong> "Revoke all temporary links for sensitive/"
                    </li>
                    <li>
                      <strong>Monitor Usage:</strong> "What's our storage consumption this month?"
                    </li>
                  </ul>
                </div>

                <div className="button-group" style={{ marginTop: '2rem' }}>
                  <Button kind="danger" onClick={handleDisconnect}>
                    Disconnect S3
                  </Button>
                  <Button kind="secondary" onClick={() => navigate('/')}>
                    Back to App
                  </Button>
                </div>
              </>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default S3IntegrationPage;
