import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  MultiSelect,
  RadioButtonGroup,
  RadioButton,
  Loading
} from '@carbon/react';
import { useNavigate } from 'react-router-dom';

const GoogleDriveIntegrationPage = ({ token, user }) => {
  const navigate = useNavigate();
  const [isConnected, setIsConnected] = useState(false);
  const [folders, setFolders] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [accessLevel, setAccessLevel] = useState('read');
  const [allowCreateFiles, setAllowCreateFiles] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // New loading state

  useEffect(() => {
    // Check connection status
    fetch('/mlapi/premium/integration/google-drive/status', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
      })
      .then((data) => {
        setIsConnected(data.isConnected);
        if (data.isConnected) {
          setFolders(data.folders || []);
          setSelectedFolders(data.selectedFolders || []);
          setAccessLevel(data.accessLevel || 'read');
          setAllowCreateFiles(data.allowCreateFiles || false);
        }
        setIsLoading(false); // Done loading
      })
      .catch((error) => {
        console.error('Error fetching Google Drive status:', error);
        alert('Failed to load Google Drive status');
        setIsLoading(false); // Ensure loading state is cleared even on error
      });
  }, [token]);

  const handleConnect = () => {
    fetch('/mlapi/premium/integration/google-drive/connect', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Connection initiation failed');
        return response.json();
      })
      .then((data) => {
        if (data.authUrl) {
          window.location.href = data.authUrl;
        } else {
          throw new Error('Authorization URL missing');
        }
      })
      .catch((error) => {
        console.error('Connection error:', error);
        alert('Failed to start Google Drive connection');
      });
  };

  const handleDisconnect = () => {
    if (window.confirm('Are you sure you want to disconnect Google Drive? This will revoke access and remove all synced data.')) {
      fetch('/mlapi/premium/integration/google-drive/disconnect', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
        .then((response) => {
          if (!response.ok) throw new Error('Disconnect failed');
          setIsConnected(false);
          setFolders([]);
          setSelectedFolders([]);
          setAccessLevel('read');
          setAllowCreateFiles(false);
          alert('Google Drive disconnected successfully');
        })
        .catch((error) => {
          console.error('Disconnect error:', error);
          alert('Failed to disconnect Google Drive');
        });
    }
  };

  const handleSaveSettings = () => {
    const settingsData = {
      selectedFolders: selectedFolders.map(({ id, name }) => ({ id, name })),
      accessLevel,
      allowCreateFiles
    };

    fetch('/mlapi/premium/integration/google-drive/save-settings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(settingsData)
    })
      .then((response) => {
        if (!response.ok) throw new Error('Settings save failed');
        navigate('/');
      })
      .catch((error) => {
        console.error('Save error:', error);
        alert('Failed to save settings');
      });
  };

  const handleCancel = () => {
    navigate('/');
  };

  // While loading, show the spinner
  if (isLoading) {
    return (
      <div className="google-drive-integration-page">
        <div className="google-drive-integration-container" style={{ textAlign: 'center', padding: '2rem' }}>
          <Loading description="Loading Google Drive status..." withOverlay={false} />
        </div>
      </div>
    );
  }

  return (
    <div className="google-drive-integration-page">
      <div className="google-drive-integration-container">
        <h1>Google Drive Integration</h1>
        
        {!isConnected ? (
          <div className="connection-prompt">
            <p>
              Connect your Google Drive account to allow Fresh Focus AI to read and
              create files on your behalf. You can restrict access to specific folders
              or grant read-only access.
            </p>
            <Button kind="primary" onClick={handleConnect}>
              Connect with Google Drive
            </Button>
          </div>
        ) : (
          <div className="connection-settings">
            <p>Your Google Drive account is connected. Select the folders you want to grant access to.</p>
            
            <div className="settings-section">
              <h2>Select Folders</h2>
              <MultiSelect
                id="folders-select"
                label="Available Folders"
                items={folders}
                itemToString={(item) => item?.name || ''}
                selectedItems={selectedFolders}
                onChange={({ selectedItems }) => setSelectedFolders(selectedItems)}
              />
            </div>

            <div className="settings-section">
              <h2>Access Level</h2>
              <RadioButtonGroup
                legendText="Choose access level"
                name="access-level"
                valueSelected={accessLevel}
                onChange={setAccessLevel}
              >
                <RadioButton labelText="Read-only" value="read" />
                <RadioButton labelText="Read-write" value="read-write" />
              </RadioButtonGroup>
            </div>

            <div className="settings-section">
              <Checkbox
                id="create-files"
                labelText="Allow creating and modifying files"
                checked={allowCreateFiles}
                onChange={(_, { checked }) => setAllowCreateFiles(checked)}
              />
            </div>

            <div className="action-buttons">
              <Button kind="danger" onClick={handleDisconnect}>
                Disconnect
              </Button>
              <Button kind="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button kind="primary" onClick={handleSaveSettings}>
                Save Settings
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GoogleDriveIntegrationPage;
